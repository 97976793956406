<template>
    <modal ref="modalCambiarLogo" titulo="Cambiar Logo" no-aceptar adicional="Guardar" @adicional="changeLogo()">
            <div class="row mx-0 my-3 j-center">
                <div class="position-relative d-middle-center">
                    <slim-cropper 
                    ref="logoConjunto" 
                    :options="slimOptions" 
                    class="border cr-pointer" 
                    style="height:237px;width:237px;background:#F8F9FF;border-radius:4px;z-index:4;" 
                    />
                </div>
            </div>
        </modal>
</template>
<script>
import Servicios from '~/services/tiendas'
import tiendas from '../../../router/tiendas/tiendas';
export default {
    data(){
        return {
            number: 1,
            idServicio : this.$route.params.idServicio,
            logo: null,
            nuevoBanner: null,
            slimOptions: {
              ratio: "1:1",
              label: "Subir imagen",
              buttonCancelLabel: "Cancelar",
              buttonConfirmLabel: "ok",
              buttonUploadLabel: "Ok",
      },
        }
    },
    computed:{
        crearOrEditar(){
            const name = this.$route.name
            if(name == "tiendas-proveedores.crear.parte-uno")return true
            return false
        }
    },
    props: {
        logoOriginal: {
            type: String,
            default: null
        }
    },
    watch:{
        logoOriginal(){
          this.$refs.logoConjunto.set_image(`${this.logoOriginal}`)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCambiarLogo.toggle();
        },
        async changeLogo(){
          try {
          this.logo = this.$refs.logoConjunto.get_image()

          if(this.crearOrEditar == true){
            this.$emit("actualizarLogo", this.logo)
            this.$refs.modalCambiarLogo.toggle();
          }else{
          const payload = {
              idServicio: this.idServicio,
              valor : this.logo,
              campo: 'logo'
            }

          const { data } = await Servicios.actualizarInfoEnCaliente(payload)
          this.notificacion('Éxito', data.mensaje, 'success')
          this.$emit("actualizarLogo")
          this.$refs.modalCambiarLogo.toggle();
          }} catch (error) {
            this.error_catch(error)
          }
        },
    }
}
</script>